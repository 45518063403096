@import '@Styles/variables.module.scss';

.container {
  color: $colorT2Light;

  > *:not(:last-child) {
    margin-right: 12px;

    @media screen and ($screenXs) {
      margin-right: 18px;
    }
  }
}
