@import '@Styles/variables.module.scss';

.tileContainer {
  border: 1px solid $AFSteel500;
  border-radius: 15px;
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: #fff;
}
