@import '@Styles/variables.module.scss';

.navContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow-wrap: break-word;
  padding-bottom: 20px;
  color: $AFWhiteKnight500;
  @media screen and ($screenXs) {
    padding-bottom: 0;
  }
}

.navButtonsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media screen and ($screenXs) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    font-size: 12px;
    gap: 0;
  }
}

.variableNavItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  :last-child {
    margin-top: auto;
  }
  @media screen and ($screenXs) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    font-size: 12px;
    gap: 0;
  }
}

.funderNavItems {
  :last-child {
    margin-top: 0;
  }
  :nth-last-child(2) {
    margin-top: auto;
  }
}

.logo {
  padding: 20px;
  @media screen and ($screenXs) {
    display: none;
  }
}
