@import '@Styles/variables.module.scss';

.tile {
  background-color: $AFDustyPink200;
  border: 1px solid $AFDustyPink200;
  padding: 0;
}

.container {
  display: flex;
  gap: 2em;
  @media screen and ($screenSm) {
    gap: 0;
    justify-content: space-between;
  }
}

.title {
  color: $AFNavyBlue500;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0 16px 16px;
}

.button {
  width: fit-content;
}

.img {
  padding: 16px 0;
  @media screen and ($screenSm) {
    display: none;
  }
}

.closeButton {
  height: fit-content;
}
